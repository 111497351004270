export const BG_COVER = `https://ik.imagekit.io/bxwgcojbe/ulva-hendra/cover.jpg?updatedAt=1708409293215`;
export const BG_WELCOMING_SECTION = `https://ik.imagekit.io/bxwgcojbe/ulva-hendra/welcome.jpg?updatedAt=1708409290171`;
export const BG_COUNTING = `https://user-images.githubusercontent.com/10141928/146292235-21d28e45-72b7-41e1-90ac-26a3605529e2.jpg`;
export const BG_IG_FILTER = `https://ik.imagekit.io/bxwgcojbe/ulva-hendra/filter.jpg?updatedAt=1708559852985`;
export const BG_DESKTOP_VIEW = `https://ik.imagekit.io/bxwgcojbe/ulva-hendra/dekstop.jpg?updatedAt=1708409290246`;

export const IMG_AYAT = `https://ik.imagekit.io/bxwgcojbe/ulva-hendra/footer.jpg?updatedAt=1708409290181`;
export const IMG_LOGO = `https://ik.imagekit.io/bxwgcojbe/ulva-hendra/logo.png?updatedAt=1708409289932`;

export const IMG_MAN = `https://ik.imagekit.io/bxwgcojbe/ulva-hendra/man.jpg?updatedAt=1708409290218`;
export const IMG_GIRL = `https://ik.imagekit.io/bxwgcojbe/ulva-hendra/girl.jpg?updatedAt=1708409290134`;
export const IMG_LOVE_STORY = `https://user-images.githubusercontent.com/10141928/146289521-e97786dc-98a1-43d3-9b0f-e9353b168766.jpg`;

export const IMG_PHOTO_1 = `https://ik.imagekit.io/bxwgcojbe/ulva-hendra/g1.jpg?updatedAt=1708409290210`;
export const IMG_PHOTO_2 = `https://ik.imagekit.io/bxwgcojbe/ulva-hendra/g2-neww.jpg?updatedAt=1708486821067`;
export const IMG_PHOTO_CONTENT = `https://ik.imagekit.io/bxwgcojbe/ulva-hendra/g3.jpg?updatedAt=1708409290225`;

export const IMG_COVID = {
  id: `https://user-images.githubusercontent.com/103095433/241068547-20f04f29-1531-4bc2-a5e5-c7e3ce1c2805.png`,
  en: `https://user-images.githubusercontent.com/103095433/241068561-c7d17d12-c32f-44a1-834a-af73e655b0b6.jpg`,
};

export const LOGO_INVITATO = `https://ik.imagekit.io/invitatoid/global-assets/invitato_hs3nyqsuwy_.png?updatedAt=1638541184695`;